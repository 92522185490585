const productQuantityHandler = () => {
  const increaseBtn = document.querySelector(".increase-quantity");
  const decreaseBtn = document.querySelector(".decrease-quantity");
  const qtyInput = document.querySelector(".qty");
  const priceContainer = document.querySelector(".right .price span");
  let price = priceContainer.textContent;

  increaseBtn.addEventListener("click", e => {
    e.preventDefault();
    let value = parseInt(qtyInput.value);
    value += 1;
    qtyInput.value = value;

    priceContainer.textContent = price * value;
  });

  decreaseBtn.addEventListener("click", e => {
    e.preventDefault();
    let value = parseInt(qtyInput.value);
    if (value > 1) {
      value -= 1;
      qtyInput.value = value;
      priceContainer.textContent = price * value;
    }
  });
};

export default productQuantityHandler;
