import $ from "jquery";

const addToCartAjax = () => {
  const btns = document.querySelectorAll(".button.add-to-cart");
  const popupContainer = document.querySelector(".popup-container");
  const quantityInput = document.querySelector("input.qty");

  for (const btn of btns) {
    btn.addEventListener("click", e => {
      e.preventDefault();
      const varID = btn.getAttribute("data-var-id");

      if (varID > 0) {
        const fd = new FormData();
        fd.append("id", varID);
        fd.append("action", "addToCartWithAjax");

        if (quantityInput) {
          fd.append("quantity", quantityInput.value);
        }

        popupContainer.classList.add("open");

        fetch(jsData.ajaxUrl, {
          method: "post",
          body: fd
        })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              popupContainer.firstElementChild.innerHTML =
              '<div class="added__product"><img src="/wp-content/uploads/2022/12/check.svg" />Produkt został dodany do koszyka</div>';
              // $(document.body).trigger("wc_fragment_refresh");
            } else {
              popupContainer.firstElementChild.innerHTML =
                "Nie udało się dodać produktu do koszyka";
            }
            setTimeout(() => {
              popupContainer.classList.remove("open");
            }, 3000);
          });
      }
    });
  }
};

export default addToCartAjax;
