const productsAttributesSelection = () => {
  const inputs = document.querySelectorAll(".variations input[type='radio']");
  let attrUrl = "?";
  inputs.forEach(input => {
    input.addEventListener("click", () => {
      const checkedInputs = document.querySelectorAll(
        ".variations input:checked"
      );
      checkedInputs.forEach(item => {
        const attr_name = item.getAttribute("name");
        const attr_value = item.getAttribute("value");
        attrUrl += `${attr_name}=${attr_value}&`;
      });
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${attrUrl}`;
      window.location.href = newUrl;
    });
  });
};

export default productsAttributesSelection;
