import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import frontPageTabs from "./frontPageTabs";
import productsAttributesSelection from "./woocommerce/productAttributesSelection";
import productQuantityHandler from "./woocommerce/productQuantityHandler";
import addToCartAjax from "./woocommerce/addToCartAjax";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  addToCartAjax();

  // const frontPageTabsContainer = document.querySelector(".tabs-container");
  // if (frontPageTabsContainer) {
  //   frontPageTabs();
  // }

  if (document.body.classList.contains("single-product")) {
    productsAttributesSelection();
  }

  if (document.querySelector(".quantity")) {
    productQuantityHandler();
  }

  searchBoxHandler();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

const searchBoxHandler = () => {
  const searchBox = document.querySelector(".search-form-container");
  const openBtn = document.querySelector(".open-search-form");
  const closeBtn = document.querySelector(".close-search-form");
  openBtn.addEventListener("click", e => {
    e.preventDefault();
    searchBox.classList.toggle("open");
    closeBtn.addEventListener("click", e => {
      e.preventDefault();
      searchBox.classList.remove("open");
    });
  });
};



document.addEventListener('DOMContentLoaded', function() {
  const showDescBtn = document.getElementById('showDesc');
  const descriptionText = document.querySelector('.description-text');

  if (window.innerWidth < 767) {
    
    if (showDescBtn && descriptionText) {

      if (descriptionText.scrollHeight >= 700) {
          showDescBtn.style.display = 'block';
          descriptionText.style.maxHeight = '700px';
          descriptionText.classList.add('hide');
          descriptionText.style.overflow = 'hidden';
      } else {
          showDescBtn.style.display = 'none';
      }

      // Obsługuje kliknięcie przycisku "Rozwiń"
      showDescBtn.addEventListener('click', function() {
        descriptionText.style.maxHeight = 'none';
        showDescBtn.style.display = 'none';
        descriptionText.classList.remove('hide');
      });
    }
  }
});




document.addEventListener('DOMContentLoaded', function() {
  const showProductDescBtn = document.getElementById('showProductDesc');
  const productDesc = document.querySelector('.woocommerce-Tabs-panel--description');

  if (window.innerWidth < 767) {
    
    if (showProductDescBtn && productDesc) {

      if (productDesc.scrollHeight >= 700) {
        showProductDescBtn.style.display = 'block';
        productDesc.style.maxHeight = '700px';
        productDesc.classList.add('hide');
        productDesc.style.overflow = 'hidden';
      } else {
        showProductDescBtn.style.display = 'none';
      }

      // Obsługuje kliknięcie przycisku "Rozwiń"
      showProductDescBtn.addEventListener('click', function() {
        productDesc.style.maxHeight = 'none';
        showProductDescBtn.style.display = 'none';
        productDesc.classList.remove('hide');
      });
    }
  }
});

document.addEventListener("DOMContentLoaded", function () {
  function toggleExpanded(event) {
      // Sprawdzamy szerokość okna
      if (window.innerWidth < 1260) {
          event.preventDefault(); // Zapobiegamy domyślnemu działaniu linku
          const parent = event.target.closest(".menu-item-has-children");
          if (parent) {
              parent.classList.toggle("expanded");
          }
      }
  }

  const menuLinks = document.querySelectorAll(".menu-item-has-children > a");

  menuLinks.forEach(link => {
      link.addEventListener("click", toggleExpanded);
  });

  window.addEventListener("resize", function () {
      if (window.innerWidth > 1260) {
          document.querySelectorAll(".menu-item-has-children.expanded").forEach(item => {
              item.classList.remove("expanded");
          });
      }
  });
});
