// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  homeProducts: $(".front-page-products-slider .inner")
};

sliders.homeProducts.slick({
  autoplay: true,
  autoplaySpeed: 4000,
  dots: true,
  draggable: false,
  fade: true,
  appendDots: $(".slider-nav-wrapper"),
  nextArrow: $(".slider-nav-wrapper .next"),
  prevArrow: "" // empty string in order to hide it
});

const fullSlides = document.querySelectorAll(
  ".front-page-products-slider .single-slide.full"
);

if (fullSlides.length) {
  fullSlides.forEach(slide => {
    if (slide.dataset.url !== "") {
      slide.addEventListener("click", () => {
        window.location.href = slide.dataset.url;
      });
    }
  });
}




  $(".product-slider").slick({
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    arrows: true,
    nextArrow: $(".product-slider-nav .next"),
    prevArrow: $(".product-slider-nav .prev"),
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  });




  $(".homepage-slider").slick({
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    infinite: false,
  });

